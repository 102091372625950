
import { defineComponent, shallowRef } from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import { modalService } from './index'

export default defineComponent({
  name: 'modal',
  props: {},
  computed: {},
  data(): any {
    return {
      uid: 0,
      dynamicModals: [],
    }
  },
  created() {
    modalService._setDynamicContainer(this)
  },
  methods: {
    calcStyle(config: any = {}) {
      let style: any = {
        marginTop: '15vh',
      }
      if (config.width) {
        style.width = config.width
      }
      return style
    },
    add(modal: any, config: any = {}, promise: Promise<any>) {
      const id = this.uid++
      if (modal) {
        config.modalTitle = config.title
        delete config.title
        const contentConfig = cloneDeep(config)
        // delete contentConfig.title
        const modalDefaultOptions = {
          // fullscreen:
          // zIndex: 1025
        }
        config = {
          ...modalDefaultOptions,
          ...config,
        }
        this.dynamicModals.push({
          id: id,
          visible: true,
          config: config,
          contentConfig: contentConfig,
          component: shallowRef(modal),
          promise: promise,
        })
      }
    },
    remove(id: number) {
      for (const i in this.dynamicModals) {
        if (this.dynamicModals[i].id === id) {
          this.dynamicModals.splice(i, 1)
          return
        }
      }
    },
    close(id: number, $event: EventTarget) {
      /**  触发close 返回Promise, 可传参回调用modal的实例中, close方法用于外部关闭 */
      for (const i in this.dynamicModals) {
        if (this.dynamicModals[i].id === id) {
          const { resolve } = this.dynamicModals[i].promise
          const reParams = cloneDeep($event)
          resolve({
            data: reParams,
            props: this.dynamicModals[i].contentConfig,
            close: () => {
              this.dynamicModals.splice(i, 1)
            },
          })
          return
        }
      }
    },
    off(id: number, $event: EventTarget) {
      /** modal 内部直接关闭 */
      for (const i in this.dynamicModals) {
        if (this.dynamicModals[i].id === id) {
          const { resolve } = this.dynamicModals[i].promise
          const reParams = cloneDeep($event)
          this.dynamicModals.splice(i, 1)
          resolve(reParams)
        }
      }
    },
  },
})
