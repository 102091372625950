import modalContainer from './modalContainer.vue'
// import { IModal } from './modal'
import './modal.less';

interface Idialog {
	_setDynamicContainer: any
	clear: any
	openWindow: any
}

interface IModal {
	dynamicContainer: any
	dialog: Idialog
}


// import { createVNode, render } from 'vue'
// import type { App } from 'vue'

const Modal: IModal = {
	dynamicContainer: null,
	dialog: {
		_setDynamicContainer(container: any) {
			Modal.dynamicContainer = container
		},

		/**
		 *
		 * clear all ModalComponent
		 */
		clear() {
			if (Modal.dynamicContainer?.dynamicModals?.length) {
				Modal.dynamicContainer.dynamicModals = []
			}
		},

		/**
		 *
		 * @param {Object} modalComponent : 组件选项对象
		 * @param {Object} props
		 */
		openWindow(modalComponent: any, props: any) {
			return new Promise((resolve, reject) => {
				Modal.dynamicContainer.add(modalComponent, props, {
					resolve: resolve,
					reject: reject
				})
			})
		}
	}
}

const modalService = Modal.dialog

// export default {
// 	//组件祖册
// 	install(app: App): void {
// 		const container = document.createElement('div')
// 		//创建虚拟节点
// 		const vm = createVNode(modalContainer)
// 		//渲染虚拟节点
// 		render(vm, container)
// 		document.body.appendChild(container)
// 		app.config.globalProperties.$modalService = modalService
// 	}
// }

export default (app: any, createMyApp: any) => {
	app.config.globalProperties.$modalService = Modal.dialog
	const modalsContainer = document.createElement('div')
	document.body.appendChild(modalsContainer)

	createMyApp(modalContainer).mount(modalsContainer)
}

export { modalService }
